<template>
  <div class="about container" v-if="page">

    <div v-for="imageGroup in page.imageGroups" :class="imageGroup.class">
      <div v-for="(image, key) in imageGroup.images">
        <img class="project-img" :srcset="`${imgPath(image)} 1x, ${imgPath(image, '@2x')} 2x`" :src="imgPath(image)" :alt="name" :loading="key <= 1 ? 'eager' : 'lazy'">
      </div>
    </div>

    <div class="description">
      <div class="description__text">
        <h1 class="mb-sm">{{ page.name }}</h1>
        <div v-html="page.description"></div>
      </div>

      <img class="description__img" :srcset="`${imgPath(page.descriptionImg)} 1x, ${imgPath(page.descriptionImg, '@2x')} 2x`" :src="imgPath(page.descriptionImg)" :alt="name" loading="lazy">
    </div>

    <router-link class="next-project" v-if="page.next" :to="{name: page.next}">next project</router-link>
  </div>
</template>

<script>
export default {
  async created() {
    // If browser doesn't have createImageBitmap, we can't use webp.
    if (!self.createImageBitmap) {
      this.webpSupported = false;
    }

    let data;

    switch (this.$route.name) {
      case 'branding':
        data = {
          name: 'simplicity, inc.',
          description: '' +
              '<p>Simplicity is a fast-growing tech company developing smart communication platform for cities and their residents. Simplicity has raised one of the largest pre-seed investments in Central Europe and expanded to the U.S.</p>' +
              '<p>I’ve joined the startup to lead brand identity definition with the development of brand guidelines. The challenge was to clarify the positioning of the brand through internal workshops and capture the essence of Simplicity in a distinctive visual identity. The versatile branding mirrors the bold and youthfull team of Simplicity, while remaining trustworthy and accessible to all its stakeholders.</p>' +
              '<p>Design Team:<br>Martin Uhnak, Katarina Schwarz, Adam Ciganik, Tamara Shawkatova (2021–2022)</p>',
          descriptionImg: 'simplicity_img_8',
          imageGroups: [
            {
              images: [
                'simplicity_img_1',
                'simplicity_img_2',
                'simplicity_img_3',
                'simplicity_img_4',
                'simplicity_img_5',
                'simplicity_img_6',
                'simplicity_img_7',
              ],
            },
          ],
          next: 'art-direction',
          prev: null,
        }
        break;
      case 'art-direction':
        data = {
          name: 'cellophane magazine',
          description: '' +
              '<p>Cellophane is a non-profit design magazine, creating a platform for displaying the potential of today’s design youth while aiming to tackle and discuss the issues of our cities, societies and the planet. The very first issue of Cellophane was released in December 2018 entitled ’In Transition’. To interpret the moment of an immanent change, the title is also translated in its atypical format. For display typeface we chose the generative ’Phase’ by Elias Hanzer which transforms throughout the whole magazine. Cellophane received funding from the Design School Student Council, was nominated for the Best Danish Book Award, and exhibited at several publishing fairs and exhibitions.</p>' +
              '<p>Founded by:<br>Lorenzo Bigatti, Alvaro Alcaide, Elettra Renzi, Tamara Shawkatova (2018–2020)</p>',
          descriptionImg: 'cellophane_img_7',
          imageGroups: [
            {
              class: '',
              images: [
                'cellophane_img_1',
                'cellophane_img_2',
                'cellophane_img_3',
                'cellophane_img_4',
                'cellophane_img_5',
                'cellophane_img_6',
              ],
            }
          ],
          next: 'editorial',
          prev: 'branding',
        }
        break;
      case 'editorial':
        data = {
          name: 'no man\'s land',
          description: '' +
              '<p>Design has always been tied closely to the aesthetics, traditions and geographical location of a culture. In the recent years globalization dramatically affected how we experience our cultural identity. My graduation project critically reacts to current social issues by challenging the value we give to cultural properties, traditions and myths.</p>' +
              '<p>Master project @Designskolen Kolding<br>Consulting: ArtRebels Aps.<br>(2019)</p>',
          descriptionImg: 'nomansland_img_6',
          imageGroups: [
            {
              class: 'd-l-none',
              images: [
                'nomansland_img_1',
                'nomansland_img_2',
                'nomansland_img_3b',
                'nomansland_img_4',
                'nomansland_img_5',
              ],
            },
            {
              class: 'd-none d-l-block',
              images: [
                'nomansland_img_1',
                'nomansland_img_2',
                'nomansland_img_3',
                'nomansland_img_4',
                'nomansland_img_5',
              ],
            },
          ],
          next: 'tech-startups',
          prev: 'art-direction',
        }
        break;
      case 'tech-startups':
        data = {
          name: 'slido by cisco',
          description: '' +
              '<p>Slido is an international SaaS company enhancing interaction and engagement during events, meetings and lectures. Slido was acquired by Cisco Systems in May 2021 and engaged millions of users.</p>' +
              '<p>My role in the Brand Team at Slido was the ideation and execution of  both online and offline design materials. During my time at Slido I’ve also set the visual direction for our photoshoots, blog, social media, merchandise, ebooks and campaigns.</p>' +
              '<p>logo, custom typeface & color palette:<br>Go Bigname studio<br>(2019–2021)</p>',
          descriptionImg: 'slido_img_7',
          imageGroups: [
            {
              class: '',
              images: [
                'slido_img_1',
                'slido_img_2',
                'slido_img_3',
                'slido_img_4',
                'slido_img_5',
                'slido_img_6',
              ],
            },
          ],
          next: 'freelance',
          prev: 'editorial',
        }
        break;
      case 'freelance':
        data = {
          name: 'menuland',
          description: '' +
              '<p>Menuland’s vision was to create the first digital cloud kitchen in Slovakia. From the beginning of the project, studio A11 was in charge of the complete digital materialization of the idea and worked in close cooperation with the internal development team of Menuland. I collaborated with the studio taking on the task of refreshing Menuland’s branding. We introduced a new identity concept, based on a purchase receipt layout, a color palette derived from colors naturally occuring in food, and mono typography, preserving the technical appeal of their original labels.</p>' +
              '<p>Menuland s.r.o | client<br>A11 studio | project management<br>Echt studio | logo design<br>Zuzana Kopanicova | co-designer<br>(2020/21)</p>',
          descriptionImg: 'menuland_img_8',
          imageGroups: [
            {
              class: 'd-l-none',
              images: [
                'menuland_img_1',
                'menuland_img_2',
                'menuland_img_3',
                'menuland_img_4',
                'menuland_img_5a',
                'menuland_img_5b',
                'menuland_img_6',
                'menuland_img_7',
                'menuland_img_8',
              ],
            },
            {
              class: 'd-none d-l-block',
              images: [
                'menuland_img_1',
                'menuland_img_2',
                'menuland_img_3',
                'menuland_img_4',
                'menuland_img_5',
                'menuland_img_6',
                'menuland_img_7',
              ],
            },
          ],
          next: null,
          prev: 'tech-startups',
        }
        break;
    }

    this.page = data;
  },
  methods: {
    imgPath(img, suffix = '') {
      const ext = this.webpSupported ? 'webp' : 'png';
      return require(`../assets/images${suffix}/${img}.${ext}`);
    }
  },
  data() {
    return {
      webpSupported: true,
      page: {}
    }
  },
  beforeRouteEnter() {
    document.body.classList.add('body-black');
  },
  beforeRouteLeave() {
    document.body.classList.remove('body-black');
  }
}
</script>

<style lang="scss">
.body-black {
  background: black;
  color: white;

  a {
    color: white;
  }

  p {
    font-size: 18px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<style scoped lang="scss">

h1 {
  margin-top: 0;
  font-size: 36px;
  font-weight: normal;
}

.project-img {
  margin-bottom: 50px;

  @media (min-width: 1024px) {
    margin-bottom: 180px;
  }
}

.description {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &__text {
    max-width: 430px;
  }

  &__img {
    display: none;
    max-width: 750px;

    @media (min-width: 1024px) {
      display: block;
    }
  }
}

.d-none {
  display: none;
}

.next-project {
  display: flex;
  margin-top: 30px;
  font-size: 36px;
}

@media (min-width: 1024px) {
  .d-l-none {
    display: none;
  }
  .d-l-block {
    display: block;
  }

  .next-project {
    justify-content: flex-end;
  }
}
</style>
