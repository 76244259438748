import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProjectView from '../views/ProjectView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/simplicity',
    name: 'branding',
    component: ProjectView,
  },
  {
    path: '/cellophane',
    name: 'art-direction',
    component: ProjectView,
  },
  {
    path: '/no-mans-land',
    name: 'editorial',
    component: ProjectView,
  },
  {
    path: '/slido',
    name: 'tech-startups',
    component: ProjectView,
  },
  {
    path: '/menuland',
    name: 'freelance',
    component: ProjectView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
