<template>
  <div class="home container">
    <img alt="hey, i’m tamara. communication designer w/ focus on" :src="require(`../assets/images/intro-text/hey-im-tamara.svg`)" >
    <router-link :to="{name: 'branding'}">
      <img alt="branding" :src="require(`../assets/images/intro-text/branding.svg`)" >
    </router-link>
    <router-link :to="{name: 'art-direction'}">
      <img alt="art direction," :src="require(`../assets/images/intro-text/art-direction.svg`)" >
    </router-link>
    <router-link :to="{name: 'editorial'}">
      <img alt="& editorial;" :src="require(`../assets/images/intro-text/editorial.svg`)" >
    </router-link>
    <img alt="experienced in" :src="require(`../assets/images/intro-text/experienced-in.svg`)" >
    <router-link :to="{name: 'tech-startups'}">
      <img alt="tech startups" :src="require(`../assets/images/intro-text/tech-startups.svg`)" >
    </router-link>
    <router-link :to="{name: 'freelance'}">
      <img alt="& freelance." :src="require(`../assets/images/intro-text/freelance.svg`)" >
    </router-link>
  </div>



</template>

<script>
export default {
  name: 'HomeView',
}
</script>

<style lang="scss">
.home {
  font-size: 14vw;
  line-height: 1;
  display: flex;
  flex-direction: column;

  a {
    display: flex;
  }
}
</style>
