<template>
  <nav class="container mb-lg">
    <router-link to="/">about</router-link>
    <router-link :to="{name: 'branding'}">work</router-link>
  </nav>
  <router-view class="mb-xl" :key="$route.fullPath"/>
  <nav class="container">
    <a href="mailto:tamara@shawkatova.com">e-mail</a>
    <span>
      &copy; 2022 Tamara Shawkatova
    </span>
    <a href="https://www.linkedin.com/in/tamara-širin-shawkatová-179692113/" target="_blank">linkedin</a>
  </nav>
</template>

<style lang="scss">

@import '@/assets/font/stylesheet.css';

body {
  margin: 0;
  padding: 0 25px;

  @media(min-width: 1200px) {
    padding: 0 75px;
  }
}

#app {
  font-family: 'Founders Grotesk', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;

  img {
    max-width: 100%;
  }
}

a {
  color: black;
}

nav {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: block;
    padding: 12px 0;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
  }

  @media (min-width: 1024px) {
    font-size: 21px;

    a {
      padding: 24px 0;
    }
  }
}

.mb-sm {
  margin-bottom: 24px;
}

.mb-md {
  margin-bottom: 48px;
}

.mb-lg {
  margin-bottom: 72px;
}

.mb-xl {
  margin-bottom: 144px;
}
</style>
